<template>
  <v-card class="n-cccard">
    <div style="padding: 10px;">
      <v-card-title>两步验证</v-card-title>
      <v-card-subtitle style="white-space: normal">绑定两步验证工具以确保您的 BoxCat 帐号安全。</v-card-subtitle>
      <v-card-text style="margin-top: 10px">
        <router-link to="/settings/security-2fa/email/">
          <div class="n-card">
            <div class="n-card-content">
              <div class="c-name">辅助验证邮箱</div>
              <div class="n-card-value">
                <span v-text="user.safety.mfa.method.email.address" v-if="user.safety.mfa.method.email.enable"></span>
                <span v-else>未绑定</span>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
        <router-link to="/settings/security-2fa/tel/">
          <div class="n-card">
            <div class="n-card-content">
              <div class="c-name">辅助验证手机</div>
              <div class="n-card-value">
                <span v-text="user.safety.mfa.method.tel.number" v-if="user.safety.mfa.method.tel.enable"></span>
                <span v-else>未绑定</span>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
        <router-link to="/settings/security-2fa/google/">
          <div class="n-card">
            <div class="n-card-content">
              <div class="c-name">身份验证器</div>
              <div class="n-card-value">
                <span v-if="user.safety.mfa.method.google.enable">已启用</span>
                <span v-if="!user.safety.mfa.method.google.enable">未绑定</span>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "MfA",
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
  })
};
</script>
<style scoped>
.n-card{
  display:flex;
  min-height: 50px;
  align-items: center;
}
.c-name{
  font-size: 18px;
  display: flex;
  align-items: center;
}
.n-card-content{
  display:flex;
}
@media(max-width: 600px) {
  .n-cccard{
    margin: 5px;
  }
  .n-card{
    height: auto;
    justify-content: space-between;
  }
  .c-name{
    padding-top:2px;
    margin-right: 15px;
  }
  .n-card-content{
    align-items: center;
  }
  .n-card-value{
    display: flex;
    font-size: 14px;
    flex: 1;
    color: gray;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
  .n-card{
    height: 60px;
  }
  .c-name{
    flex-basis: 150px;
  }
  .n-card-content{
    flex-grow: 1;
    text-align: center;
    align-items: center;
    font-size: 10px;
  }
  .n-card-value{
    display: flex;
    font-size: 15px;
    min-width: 200px;
    flex: 1;
    color: gray;
  }
}

@media(max-width: 820px) {
  .n-card-value{
    min-width: 180px;
  }
}

@media(max-width: 760px) {
  .n-card-value{
    min-width: 170px;
  }
}

@media(max-width: 700px) {
  .n-card-value{
    min-width: 160px;
  }
}

@media(max-width: 640px) {
  .n-card-value{
    min-width: 150px;
  }
}
</style>