// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

const nekoTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        accent: '#FFFFFF',
        surface: '#FFFFFF',
        primary: 'rgb(245,143,178)',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
    light:{
        secondary: 'rgb(245,143,178)',
    }
}

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    theme: {
        defaultTheme: 'nekoTheme',
        themes: {
            nekoTheme
        }
    }
})
