<script>
import {defineComponent} from 'vue'
import Swal from "sweetalert2";
import {toCanvas} from "qrcode";
import {load} from "recaptcha-v3";

export default defineComponent({
  name: "QrCode",
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
  }),
  mounted() {
    this.getTOTPurl()
  },
  methods: {
   getTOTPurl() {
     load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
       recaptcha.execute('bindGoogle').then((token) => {
         this.$axios.post("/api/security/verify/MFA/url/",
             JSON.stringify({
               "g-recaptcha-response": token,
             }),
             {
           headers: {
             Authorization: "Bearer " + this.$cookie.getCookie('token'),
           }
         }).then(res => {
           const json = res.data
           document.getElementById('qrcode_box').innerHTML = '<canvas id="qrcode" style="width: 128px; height: 128px;"></canvas>'
           toCanvas(document.getElementById("qrcode"), json['data']['url'])
         }).catch(
             err => {
               const res = err.response
               Swal.fire("获取失败", res.data.message, "error")
             }
         )
       })
     })
   }
  }
})
</script>

<template>
  <div id="qrcode_box">
    <img id="load" src="img/loading_pink.webp" style="width: 128px; height: 128px;"/>
  </div>
  
</template>