<template>
  <div style="margin-top:64px">
    <div>
      <div style="max-width: 600px; min-height: 50px;margin: auto">
      <div class="m-title">
        <a @click="$router.push({name: 'home'});">
          <v-icon>mdi-arrow-left</v-icon>
        </a>
        <div v-text="col" style="margin-left: 15px; font-size: 25px">
        </div>
      </div>
      </div>
      <v-divider></v-divider>
    </div>
    <div style="max-width: 650px;min-height: 500px; margin: 20px auto auto;">
      <router-view  :user="user"></router-view>
    </div>
  </div>
</template>
<style scoped>
.m-title{
  display: flex;
  align-items: center;
  top: 64px;
  height:50px;
  font-size: 20px;
}
</style>
<script>
const cols = {
  'profile-nick': '昵称',
  'profile-birth': '生日',
  'profile-sex': '性别',
  'profile-avatar': '头像',
  'security-password': '密码',
  'security-tel': '手机号码',
  'security-email': '电子邮件',
  'security-2fa': '两步验证',
  'privacy-apps': '隐私',
};
export default {
  name: 'SettingsChange',
  components: {
  },
  props:{
    user: Object,
  },
  data: () => ({
    col: ''
  }),
  mounted() {
    this.$data.col = cols[this.$route.name];
    document.title = this.$data.col;
    if((/security-\w+/).test(this.$route.name)){
      if(this.user.safety.mfa.enable){
        if(!this.$cookie.getCookie('SafetyToken')){
          if(!this.$cookie.getCookie('getAuthCode')){
            this.$router.push({name: 'verify-password', query: {callback: this.$route.name}});
          }else {
              this.$router.push({name: 'verify-2fa', query: {callback: this.$route.name}});
          }
        }
      }else{
        if(!this.$cookie.getCookie('SafetyToken')){
          this.$router.push({name: 'verify-password', query: {callback: this.$route.name}});
        }
      }
    }
  },
  methods:{
  }
};
</script>