<template>
  <div id="BoxCatUserCenter-Loading">
    <div class="loading-box">
      <img
          src="https://my.cat.beer/img/loading.webp"
          class="loading-img"  alt=""/>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorPage",
  props:{
    user: Object,
  },
  data: () => ({
  }),
  mounted() {
    document.title = '加载中...'
  }
};
</script>
<style scoped>
#BoxCatUserCenter-Loading {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  background-color: rgb(224, 143, 177);
  transition: all 0.3s;
}

#BoxCatUserCenter-Loading.loaded {
  opacity: 0;
}

#BoxCatUserCenter-Loading .loading-box {
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#BoxCatUserCenter-Loading .loading-box .loading-img {
  width: 120px;
  height: 120px;
}
</style>