<script setup>

</script>

<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
    <v-card-title></v-card-title>
    <v-card-subtitle style="white-space: normal">性别</v-card-subtitle>
    <v-card-text style="margin-top: 10px">
        <v-radio-group v-model="sex" color="primary">
          <v-radio label="男" value="男"></v-radio>
          <v-radio label="女" value="女"></v-radio>
          <v-radio label="未知" value="未知"></v-radio>
          <v-radio label="武装直升机" value="武装直升机"></v-radio>
        </v-radio-group>
        <div class="btns">
          <v-btn variant="plain" color="primary" @click="$router.go(-1)">
            取消
          </v-btn>
          <v-btn variant="tonal" color="primary" @click="setSex" :disabled="sex === user.info.sex">
            保存
          </v-btn>
        </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: 'SetSex',
  props: {
    user: {Object}
  },
  data: () => ({
    sex: '未知',
  }),
  mounted() {
    this.$data.sex = this.$props.user.info.sex;
  },
  methods: {
    setSex() {
      this.$axios.put(
          '/oauth2/user/info/write/sex/' + this.$data.sex + '/',
          '',
          {
            headers: {
              'Authorization': 'Bearer ' + this.$cookie.getCookie('token')
            }
          }
      ).then(res =>{
        const data = res.data;
        if(data['success']){
          Swal.fire({
            title: '成功',
            text: '修改成功',
            icon: 'success',
            confirmButtonText: '确定',
          }).then(() => {
            this.$router.push({name: 'profile'});
          })
        }
      }).catch(
          err=>{
            const data = err.response.data;
            Swal.fire({
              title: '错误',
              text: data['message'],
              icon: 'error',
              confirmButtonText: '确定',
            })
          }
      )
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;

  }
}
.btns{
  display: flex;
  justify-content: flex-end;
}
</style>