<template>
  <div
      style="
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex"
  >
    <div></div>
    <v-card class="n-cccard">
      <v-card-title>:) 似乎出现了意外的情况</v-card-title>
      <v-card-subtitle style="white-space: normal"><v-icon icon="mdi-link-variant"></v-icon>my.cat.beer</v-card-subtitle>
      <v-card-text style="margin-top: 10px">
        <div class="error">
          <div>
            <div><span style="font-size: 50px;color:rgb(245,143,178)" v-text="data.status"></span></div>
            <div style="margin-top: 20px;"><span style="font-size: 30px;color: rgb(245,143,178)" v-text="data.message"></span></div>
          </div>
          <div>
            <img src="https://s1.ax1x.com/2023/06/11/pCZQt8e.png" alt="" style="height: 150px">
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <div style="display: flex; justify-content: space-between; flex-direction: row;align-items: center;margin-left: 10px;margin-right: 10px">
      <div>
        <span style="font-size: 15px;margin-top: 30px;color: #999">请检查您输入的网址是否正确</span>
      </div>
      <div>
        <v-btn color="primary" variant="plain" @click="$router.push('/')">返回主页</v-btn>
      </div>
    </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "ErrorPage",
  props:{
    user: Object,
  },
  data: () => ({
    data: {
      status: 404,
      message: '页面不存在'
    }
  }),
  mounted() {
    document.title = '出错啦'
    if('message' in this.user){
      this.$data.data = this.user
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
}
@media(max-width: 700px) {
  .n-cccard{
    width: 96%;
  }
}
@media(min-width: 700px) {
  .n-cccard{
    width: 700px;
  }
}
.error{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 20px;
}
</style>