<template>
  <v-tabs
      bg-color="white"
      align-tabs="center"
      class="n-tab"
      app
      color="primary"
      style="position: fixed; top: 64px; width: 100%;z-index: 999"
  >
    <v-tab to="/home" style="">首页</v-tab>
    <v-tab to="/profile" value="profile">个人资料</v-tab>
    <v-tab to="/security" value="security">账号与安全</v-tab>
    <v-tab to="/privacy" value="privacy">数据与隐私</v-tab>
    <a href="https://boxcat.org"><v-tab value="about">关于</v-tab></a>
  </v-tabs>
</template>

<style scoped>
a{
  color: rgb(245,143,178)
}
</style>

<script>
export default {
  name: 'TabBar',
  data: () => ({

  })
};
</script>