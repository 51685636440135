<template>
  <v-app>
    <ToolBar :user="user" v-if="user.info.uuid !== ''"></ToolBar>
    <v-main>
      <router-view :user="user"></router-view>
    </v-main>
    <div style="max-height: 100px; margin-top:15px;"><FooterBar></FooterBar></div>
  </v-app>
  <LoadPage v-if="loading"></LoadPage>
</template>

<script>
import FooterBar from "@/components/home/FooterBar.vue";
import ToolBar from "@/components/home/ToolBar.vue";
import Swal from "sweetalert2";
import LoadPage from "@/components/LoadPage.vue";
document.title = "BoxCat 账户";

export default {
  name: 'App',
  components: {LoadPage, ToolBar, FooterBar},
  data: () => ({
    tab: null,
    loading: true,
    user:{
      info: {
            uuid: '',
            nick: '加载中',
            sex: '未知',
            email: '未绑定',
            birthday: '1919年8月10日',
            avatar: 'img/no_avatar.webp',
            tel: '+86 11451419190',
          },
      safety: {
        mfa: {
          enable: true,
          method: {
            email: {
              enable: true,
              address: 'n**o@nya.cat'
            },
            tel: {
              enable: true,
              number: '168***2333'
            },
            google: {
              enable: true,
            },
          }
        },
      }
    },
  }),
  mounted() {
    if(this.$cookie.getCookie('token')){
      this.read();
    }else {
      this.$data.loading = false
      this.$router.push({name: 'login'});
    }
  },
  methods: {
    loaded() {
      const BoxCatUserCenterLoading = document.querySelector("#BoxCatUserCenter-Loading");
      BoxCatUserCenterLoading.classList.add("loaded");
      setTimeout(function() {
        BoxCatUserCenterLoading.style.display = "none";
        setTimeout(function() {
          BoxCatUserCenterLoading.remove();
        }, 10)
      }, 310);
    },
    read() {
      this.$axios.get('/oauth2/user/me/',{
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.$cookie.getCookie('token'),
        },
      }).then(res =>{
          const json = res.data
          if(json['success'] === true){
            this.$data.user.info = json['data'];
            this.get_methods();
            this.$data.loading = false

          }else{
            Swal.fire( "获取用户信息失败" ,  json.message ,  "error")
          }
      }).catch(err => {
        const res = err.response
        if(res.status === 401){
          this.$cookie.removeCookie('token');
          this.$data.loading = false
          this.$router.push({name: 'login'});
        }else{
          Swal.fire( "获取用户信息失败" ,  '未知错误' ,  "error")
        }
      })
    },
    get_methods(){
      this.$axios.get('/api/security/verify/MFA/method/',{
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.$cookie.getCookie('token'),
        },
      }).then(res =>{
        const json = res.data
        if(json['success'] === true){
          this.$data.user.safety.mfa = json['data'];
          this.$data.loading = false
        }else{
          Swal.fire( "获取安全工具失败" ,  json.message ,  "error")
        }
      }).catch(err => {
        const res = err.response
          console.log(res.data['message'])
      })
    }
  },
};
</script>
<style>
html {
  overflow-y: auto !important;
}

html::-webkit-scrollbar-track-piece {
  background: rgba(0, 0, 0, 0);
}

html::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

html::-webkit-scrollbar-thumb {
  height: 40px;
  background-color: rgba(165, 165, 165, 1);
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

::selection {
  color: #fff;
  background: rgba(245, 143, 178, 0.56);
}
</style>