<script setup>

</script>

<template>
  <div class="n-title">
    <v-avatar
        style="border: 1px solid gray;"
        size="100"
    >
      <img :src="user.info.avatar" :alt="user.info.nick" style="width: 100px">
    </v-avatar>
    <p class="title">欢迎使用，<span v-text="user.info.nick"></span></p>
    <p class="desc">管理自己的信息、隐私和安全，让 BoxCat 服务更好地满足您的需求。 </p>
  </div>
  <!-- 基本资料 -->
  <v-card class="n-cccard" elevation="1">
    <div style="padding: 10px;">
      <v-card-title>隐私权和个性化</v-card-title>
      <v-card-subtitle>查看您 BoxCat 帐号中的数据，并选择保存哪些活动以用于个性化您的 BoxCat 体验</v-card-subtitle>
      <br>
      <v-divider></v-divider>
      <v-card-text style="margin-top: 10px">
        <router-link to="/privacy" style="color:deepskyblue; font-size: 20px">
          管理您的隐私权和个性化设置
        </router-link>
      </v-card-text>
    </div>
  </v-card>
  <!-- 联系方式 -->
  <v-card class="n-cccard" elevation="1">
    <div style="padding: 10px;">
      <v-card-title>您有安全提示</v-card-title>
      <v-card-subtitle>系统根据安全检查结果给出了安全提示</v-card-subtitle>
      <br>
      <v-divider></v-divider>
      <v-card-text style="margin-top: 10px">
        <router-link to="/security" style="color:deepskyblue; font-size: 20px">
          查看安全提示
        </router-link>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'HomePage',
  props: {
    user: Object
  },
  data: () => ({
  }),
  mounted() {
    document.title = 'BoxCat 账号'
  }
};
</script>
<style scoped>
.n-title{
  text-align: center;
  margin: 20px;
}
.n-title .title{
  font-size: 30px;
}
.n-title .desc{
  font-size: 25px;
  color: #999;
}

@media(max-width: 600px) {
  .n-cccard{
    margin: 5px;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
}
</style>