<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
    <v-card-title>个性头像</v-card-title>
    <v-card-subtitle style="white-space: normal">头像可帮助他人认出您，还能让您知道目前登录的是否是自己的帐号</v-card-subtitle>
    <v-card-text style="margin-top: 10px">
        <div class="cutimg">
          <div class="preview">
            <div style="width: 256px; height: 256px">
              <img :src="preview" alt="" style="width: 256px;border: #999999 1px solid;">
            </div>
            <div style="margin-top: 20px">
              <v-file-input
                  ref="fileInput"
                  accept="image/png, image/jpeg, image/bmp"
                  hide-details
                  @change="uploadImage"
                  v-model="file"
                  style="display: none;"
              ></v-file-input>
              <v-btn color="#66b1ff" variant="tonal" @click="this.$refs.fileInput.click()">
                上传新头像
              </v-btn>
            </div>
          </div>
          <div style="width: 100%;height: auto; margin-top: 20px" v-if="pic !== null">
          <div style="width: 100%;height: 300px" >
          <vue-cropper
              ref="cropper"
              :img="pic"
              outputType="png"
              :outputSize=1
              :info="true"
              :canScale="true"
              :autoCrop="true"
              :autoCropWidth="512"
              :autoCropHeight="512"
              :fixed="true"
              :flxedNumber=[1,1]
              :canMove="true"
              :canMoveBox="true"
              :centerBox="true"
              mode="cover"
              fillColor="white"
              @realTime="printimg"
          >
          </vue-cropper>
          </div>
            <div class="buttons">
              <v-btn color="primary" variant="plain">取消</v-btn>
              <v-btn color="primary" variant="tonal" style="margin-left: 10px" @click="uploadAvatar">确定</v-btn>
            </div>
          </div>
        </div>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: 'SetAvatar',
  components: {
  },
  props: {
    user: {
      Object,
    }
  },
  data: () => ({
    nickname: '',
    preview: '',
    up_img: '',
    pic: null,
    file: null,
  }),
  mounted() {
    this.$data.preview = this.$props.user.info.avatar;
  },
  methods: {
    printimg () {
      this.$refs.cropper.getCropBlob(data => {
        this.$data.preview = URL.createObjectURL(data);
      })
    },
    uploadImage(){
      if(this.$data.file.length > 0){
        readFileAsDataURL(this.$data.file[0])
            .then((dataURL) => {
              this.$data.pic = dataURL;
              // 在这里进行进一步的操作，如显示预览图像等
            })
            .catch((error) => {
              console.error('Error reading file:', error);
            });
      }
    },
    uploadAvatar(){
      this.$refs.cropper.getCropBlob(data => {
        let form = new FormData();
        form.append('file', data, 'test.png');
        if(this.$data.preview.length >0){
          this.$axios.post(
              '/oauth2/user/info/write/avatar/',
              form,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': 'Bearer ' + this.$cookie.getCookie('token')
                }
              }
          ).then(res =>{
            const data = res.data
            if(data['success']){
              Swal.fire({
                icon: 'success',
                title: '头像修改成功',
              }).then(s => {
                if(s.isConfirmed){
                  this.$router.push({name: 'profile'});
                }
              })
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    }
  },
};
function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (event) => {
      reject(event.target.error);
    };
    reader.readAsDataURL(file);
  });
}
</script>

<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  min-height: 500px;
  margin: auto;
}
.buttons{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;
  }
}
.cutimg{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview{
  display: flex;
  align-items: center;
  flex-direction: column;
}

</style>