<template>
  <div
      style="
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex"
  >
    <div style="margin-bottom: -15px;z-index: 999;height: 100px;display: flex;flex-direction: column;justify-content: flex-end" v-if="show">
      <div>
        <img src="https://my.cat.beer/img/show.webp" alt="" style="width: 100px">
      </div>
    </div>
    <div style="margin-bottom: -15px; height: 100px;" v-if="!show">
      <div style="margin-top: -8px"><img src="https://my.cat.beer/img/hide.webp" alt="" style="height: 100px"></div>
    </div>
    <v-card class="n-cccard">
      <v-card-title>登录</v-card-title>
      <v-card-subtitle style="white-space: normal">登录到您的 BoxCat 账号</v-card-subtitle>
      <v-divider style="margin-bottom: 15px;margin-top: 15px"></v-divider>
      <v-card-text style="margin-top: 10px">
        <v-text-field color="primary" label="用户名或邮箱" v-model="username" variant="outlined" @focus="show = true">
        </v-text-field>
        <v-text-field color="primary" label="密码" type="password" v-model="password" variant="outlined" @focus="show = false">
        </v-text-field>
        <div class="btns">
          <div style="display: flex;flex-direction: column">
            <div>
              <span>没有账户？</span>
              <router-link style="color: #F58FB2" to="/register">点击注册</router-link>
            </div>
            <div>
              <span>忘记密码？</span>
              <router-link style="color: #F58FB2" to="/forget">点击重置</router-link>
            </div>
          </div>
          <div>
            <v-btn variant="tonal" color="primary" :disabled="password.length < 8 || username.length < 4" @click="login" @keyup.enter="login">登录</v-btn>
          </div>
        </div>
      </v-card-text>
      <v-divider style="margin-top: 20px"></v-divider>
      <div
          style="display: flex;
           justify-content: center;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 14px;
            height: 30px;
"
      >
        登录即代表您已阅读并同意<a style="color: #66b1ff" href="" >用户协议</a>和<a style="color: #66b1ff" href="" >隐私政策</a>
    </div>
    </v-card>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { load } from 'recaptcha-v3'
import Load from "@/plugins/load";

export default {
  name: "LoginPage",
  data: () => ({
    username: '',
    password: '',
    show: true,
  }),
  mounted() {
    document.title = '登录'
    if(this.$cookie.getCookie('token')){
      if(this.$route.query.callback){
        window.location.href = this.$route.query.callback
      }else{
        this.$router.push({path: '/home'})
      }
    }
  },
  methods:{
    login() {
      const l = new Load()
      l.show()
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('login').then((token) => {
          this.$axios.post(
                '/api/login/',
              JSON.stringify({
                'username': this.$data.username,
                'password': this.$data.password,
                'g-recaptcha-response': token,
              }
          )).then(
              res => {
                const json = res.data
                l.hide()
                  this.$cookie.setCookie('token', json['data']['access_token']);
                  Swal.fire({title: "登录成功", html: '欢迎回来！', icon: "success", confirmButtonText: '点击跳转'} ).then(() => {
                        this.$router.go(0)
                  })
                }
          ).catch(
              err => {
                const res = err.response
                l.hide()
                  Swal.fire( "登录失败" ,  res.data.message ,  "error" )
                }
          )
        })
        recaptcha.hideBadge()
      })
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  padding: 10px 10px 0;
}
@media(max-width: 600px) {
  .n-cccard{
    width: 96%;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    width: 380px;
  }
}
.btns{
  display: flex;
  justify-content: space-between;
}
</style>