<script setup>

</script>

<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
    <v-card-title></v-card-title>
    <v-card-subtitle style="white-space: normal">您的生日信息可能会用于确保帐号安全以及在各项 BoxCat 服务中提供个性化体验。</v-card-subtitle>
    <v-card-text style="margin-top: 10px">
      <v-form>
        <div style="display: flex;">
        <v-text-field color="primary" label="年" @change="checkDate()" v-model="temp_date.year" variant="outlined" style="width: 100px;">
        </v-text-field>
          <v-select
              color="primary"
              label="月"
              :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
              variant="outlined"
              style="width: 150px; margin-right: 20px; margin-left: 20px"
              v-model="temp_date.month"
              @update:modelValue="checkDate()"
          ></v-select>
        <v-text-field color="primary" label="日" @change="checkDate()" v-model="temp_date.day" variant="outlined" style="width: 80px">
        </v-text-field>
        </div>
        <span style="color: red" v-text="tips" v-if="tips.length > 0"></span>
        <div class="btns">
          <v-btn variant="plain" color="primary" @click="$router.go(-1)">
            取消
          </v-btn>
          <v-btn variant="tonal" color="primary" id='save' @click="update" :disabled="invalid === true">
            保存
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";

function parseDateString(dateString) {
  dateString = dateString.toString()
  // 从日期字符串中提取年、月、日
  const [year, month, day] = dateString.split(/年|月|日/);
// 构造 Date 对象
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
}

export default {
  name: 'SetNick',
  props: {
    user: {
      Object,
    }
  },
  data: () => ({
    birth_date: new Date(),
    temp_date: {
      year: 2022,
      month: 2,
      day: 2,
    },
    tips: '',
    invalid: true,
  }),
  mounted() {
    this.$data.birth_date = parseDateString(this.$props.user.info.birthday);
    const d = this.$data.birth_date;
    this.$data.temp_date.year = d.getFullYear();
    this.$data.temp_date.month = d.getMonth() + 1;
    this.$data.temp_date.day = d.getDate();
  },
  methods: {
    checkDate() {
      const d = this.$data.temp_date;
      if (d.year < 1900 || d.year > 2023 || d.month < 1 || d.month > 12 || d.day < 1 || d.day > 31) {
        this.$data.tips = '请输入正确的日期';
        this.$data.invalid = true;
        return false;
      }else{
        if(new Date(d.year, d.month, d.day).toString() === 'Invalid Date') {
          this.$data.tips = '请输入正确的日期';
          this.$data.invalid = true;
          return false;
        }
        if(d.month === this.$data.birth_date.getMonth() + 1 && d.day === this.$data.birth_date.getDate() && d.year === this.$data.birth_date.getFullYear()) {
          this.$data.tips = '';
          this.$data.invalid = true;
          return false;
        }
      }
      this.$data.tips = '';
      this.$data.invalid = false;
      return true;
    },
    update(){
      if(this.checkDate()) {
        const d = this.$data.temp_date;
        this.$axios.put(
            '/oauth2/user/info/write/birthday/' + d.year + '-' + d.month + '-' + d.day + '/',
            '',
            {
              headers: {
                'Authorization': 'Bearer ' + this.$cookie.getCookie('token')
              }
            }
        ).then(res =>{
          const data = res.data;
          if(data['success']){
            Swal.fire({
              title: '成功',
              text: '修改成功',
              icon: 'success',
              confirmButtonText: '确定',
            }).then(() => {
              this.$router.push({name: 'profile'});
            })
          }
        }).catch(
            err=>{
              const data = err.response.data;
              Swal.fire({
                title: '错误',
                text: data['message'],
                icon: 'error',
                confirmButtonText: '确定',
              })
            }
        )
      }
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;

  }
}
.btns{
  display: flex;
  justify-content: flex-end;
}
</style>