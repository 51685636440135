<script setup>

</script>

<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
      <v-card-title></v-card-title>
      <v-card-subtitle style="white-space: normal">对您的昵称所做的更改将反映到您的 BoxCat 帐号中。</v-card-subtitle>
      <v-card-text style="margin-top: 10px">
        <v-form>
          <v-text-field color="primary" label="昵称" v-model="nickname" variant="outlined">
          </v-text-field>
          <div class="btns">
            <v-btn variant="plain" color="primary" @click="$router.go(-1)">
              取消
            </v-btn>
            <v-btn variant="tonal" color="primary" :disabled="nickname === user.info.nick">
              保存
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'SetNick',
  props: {
    user: {
      Object,
    }
  },
  data: () => ({
    nickname: '',
  }),
  mounted() {
    this.$data.nickname = this.$props.user.info.nick;
  },
  methods: {
    changeNick() {
      this.$axios.post('/api/user/setNick', {
        nick: this.nickname
      }).then(res => {
        if(res.data.code === 0){
          this.$store.commit('setNick', this.nickname);
          this.$router.go(-1);
        }else{
          this.$store.commit('setSnackbar', {
            text: res.data.msg,
            color: 'error'
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;

  }
}
.btns{
  display: flex;
  justify-content: flex-end;
}
</style>