<template>
  <div style="position: absolute;top:64px; width: 100%">
    <div style="max-width: 450px; min-height: 50px;margin: auto">
      <div class="m-title">
        <a @click="$router.push({name: 'home'});">
          <v-icon>mdi-arrow-left</v-icon>
        </a>返回
        <div v-text="col" style="margin-left: 15px; font-size: 25px">
        </div>
      </div>
    </div>
    <v-divider></v-divider>
  </div>
  <div id="safety-verification-box">
    <router-view :user="user" :next="next"></router-view>
  </div>
</template>
<style>
.m-title {
  display: flex;
  align-items: center;
  top: 64px;
  height: 50px;
  font-size: 20px;
}

#safety-verification-box {
  max-width: 450px;
  min-width: 300px;
  min-height: 500px;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
<script>
export default {
  name: "SafetyVerification",
  components: {},
  props: {
    user: Object,
  },
  data: () => ({
    col: "",
    next: "",
  }),
  mounted() {
    document.title = "安全验证";
    this.$data.next = this.$route.query.callback;
  },
  methods: {},
};
</script>
