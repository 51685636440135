<script setup>

</script>

<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
    <div style="display: flex; align-items: center; justify-content: center; margin: 10px auto 5px;">
      <div>
        <img src="img/logo_pink.svg" style="  height: 40px;" alt="">
      </div>
      <div style="margin-bottom: 5px; padding-left: 10px;font-size:30px;color: rgb(245,143,178)">BoxCat</div>
    </div>
    <v-card-title style="text-align: center;font-size: 25px">两步验证</v-card-title>
    <v-card-title style="text-align: center;font-size: 25px" v-text="user.info.nick"></v-card-title>
    <div style="text-align: center">
      <v-chip color="primary">
        <v-avatar start>
          <v-img :src="user.info.avatar"></v-img>
        </v-avatar>
        <span v-text="user.info.email"></span>
      </v-chip>
    </div>
    <br>
    <v-card-subtitle style="white-space: normal; text-align: center">
      如需继续操作，请先验证您的身份
    </v-card-subtitle>
    <v-card-text style="margin-top: 10px">
        <router-view :user="user"></router-view>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: 'Verify2FA',
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    auth: '',
    authCode: '',
    callback: '/home'
  }),
  mounted() {
    this.$data.callback = this.$route.query.callback ? this.$route.query.callback : '/home'
    if(this.$cookie.getCookie('SafetyToken')) this.$router.push({path: this.$data.callback})
    if(!this.$cookie.getCookie('getAuthCode')) this.$router.push({name: 'verify-password'})
  },
  methods: {
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  width: 100%;
  margin: auto;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;
    min-height: 480px;
  }
}

</style>