<template>
  <div style="z-index: 999"><TabBar :user="user"></TabBar></div>
  <div id="main-board">
    <router-view :user="user"></router-view>
  </div>
</template>
<style>
#main-board {
  max-width: 900px;
  min-height: 700px;
  margin: 130px auto 0;
}
</style>
<script>
import TabBar from "@/components/home/TabBar.vue";

export default {
  name: "MainBoard",
  components: { TabBar },
  props: {
    user: Object,
  },
  data: () => ({}),
};
</script>
