<script setup>

</script>

<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
    <v-card-title>安全邮箱</v-card-title>
    <v-card-subtitle style="white-space: normal">修改您的安全邮箱地址。</v-card-subtitle>
    <v-card-text style="margin-top: 10px">
      <v-form>
        <v-text-field color="primary" label="新的邮箱地址" type="" v-model="mail" variant="outlined">
        </v-text-field>
        <div style="display: flex; justify-content: space-between">
          <div style="flex: 1">
            <v-text-field color="primary" label="验证码" type="" v-model="code" variant="outlined">
            </v-text-field>
          </div>
          <div >
            <v-btn variant="tonal" style="height:50px; margin-left: 20px" color="primary" @click="get_mail_code">
              获取验证码
            </v-btn>
          </div>
        </div>
        <div class="btns">
          <v-btn variant="plain" color="primary" @click="$router.go(-1)">
            取消
          </v-btn>
          <v-btn variant="tonal" color="primary" :disabled="code.length !== 6" @click="write_mail">
            提交
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import {load} from "recaptcha-v3";
import Swal from "sweetalert2";

export default {
  name: 'SecurityEmail',
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    mail: '',
    code: '',
  }),
  mounted() {
  },
  methods: {
    async get_mail_code(){
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('verify_pwd').then((token) => {
          this.$axios.post(
              '/api/security/verify/MFA/code/2/bind/',
              JSON.stringify(
                  {
                    account: this.$data.mail,
                    'g-recaptcha-response': token
                  }
              ),
              {
                headers: {
                  Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
                },
              }
          ).then(res => {
            const data = res.data;
            if(data['success']){
              Swal.fire(
                  '成功',
                  '验证码已发送至您的邮箱，请注意查收',
                  'success'
              )
            }else{
              Swal.fire(
                  '错误',
                  data['message'],
                  'error'
              )
            }
          }).catch(err=>{
            const data = err.response.data;
            Swal.fire(
                '错误',
                data['message'],
                'error'
            )
          })
        })
      })
    },
    write_mail(){
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('verify_pwd').then((token) => {
          this.$axios.put(
              '/oauth2/user/security/write/email/',
              JSON.stringify(
                  {
                    value: this.$data.mail,
                    code: this.$data.code,
                    safety_token: this.$cookie.getCookie('SafetyToken'),
                    'g-recaptcha-response': token
                  }
              ),
              {
                headers: {
                  Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
                },
              }
          ).then(res => {
            const data = res.data;
            Swal.fire(
                '成功',
                data['message'],
                'success'
            ).then(()=>{
              this.$router.go(0)
            })
          }).catch(err=>{
            const data = err.response.data;
            Swal.fire(
                '错误',
                data['message'],
                'error'
            )
          })
        })
      })
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;
  }
}
.btns{
  display: flex;
  justify-content: space-between;
}
</style>