<template>
  <div style="z-index: 999; position: fixed; width: 100%; top:0">
    <v-toolbar class="bg-white" color="primary">
      <v-toolbar-title>
        <div style="display: flex; align-items: center">
          <div>
            <img src="https://my.cat.beer/img/logo.svg" style=" width: 30px; height: 30px;" alt="">
          </div>
          <div style="margin-bottom: 5px; padding-left: 10px; color: white">BoxCat 账户</div>
        </div>
      </v-toolbar-title>
      <div v-if="$cookie.getCookie('token')">
      <v-btn icon>
        <v-avatar
            class="me-4 ms-4"
            size="32"
            style="border: 1px solid gray;"
            color="white"
            :image="user.info.avatar"
        >
        </v-avatar>
        <v-tooltip
            activator="parent"
            location="bottom"
        >
          <p>BoxCat账户</p>
          <p v-text="user.info.nick"></p>
          <p v-text="user.info.email"></p>
        </v-tooltip>
      </v-btn>
      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
        <v-tooltip
            activator="parent"
            location="bottom"
        >退出登录</v-tooltip>
      </v-btn>
      </div>
    </v-toolbar>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  props: {
    user: Object
  },
  methods: {
    logout() {
      this.$axios.post(
          '/logout/',
          {},
          {
            headers: {
              'Authorization': 'Bearer ' + this.$cookie.getCookie('token'),
            },
          }
      ).then(res =>{
        if(res.status === 200){
          this.$cookie.removeCookie('token');
          Swal.fire(
              '成功',
              '您已退出登录',
              'success'
          ).then(s => {
            if(s.isConfirmed){
              this.$router.go(0)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped>

</style>