<template>
  <div
      style="
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex"
  >
      <div>
        <img src="https://my.cat.beer/img/show.webp" alt="" style="width: 100px">
      </div>
    <v-card class="n-cccard">
      <v-card-title>注册</v-card-title>
      <v-card-subtitle style="white-space: normal">注册一个新的 BoxCat 账号</v-card-subtitle>
      <v-divider style="margin-bottom: 15px;margin-top: 15px"></v-divider>
      <v-card-text style="margin-top: 10px">
        <v-text-field color="primary" label="用户名" v-model="username" variant="outlined" @focus="show = true">
        </v-text-field>
        <v-text-field color="primary" label="密码" type="password" v-model="password" variant="outlined" @focus="show = false">
      </v-text-field>
        <v-text-field color="primary" label="昵称" v-model="nick" variant="outlined" @focus="show = true">
      </v-text-field>
        <v-text-field color="primary" label="邮箱" v-model="email" variant="outlined" @focus="show = true">
        </v-text-field>
        <div style="display: flex; justify-content: space-between">
          <div style="flex: 1">
            <v-text-field color="primary" label="验证码" :maxlength="6" variant="outlined" v-model="code">
            </v-text-field>
          </div>
          <div >
            <v-btn variant="tonal" style="height:50px; margin-left: 20px" color="primary" @click="get_mail_code" :disabled="time > 0">
              获取验证码<span v-if="time > 0">(<span v-text="time"></span>)</span>
            </v-btn>
          </div>
        </div>

        <div class="btns">
          <div style="display: flex;flex-direction: column">
            <div>
              <span>已有账户？</span>
              <router-link style="color: #F58FB2" to="/login">点击登录</router-link>
            </div>
            <div>
              <span>忘记密码？</span>
              <router-link style="color: #F58FB2" to="/forget">点击重置</router-link>
            </div>
          </div>
          <div>
            <v-btn variant="tonal" color="primary" :disabled="password.length < 8 || username.length < 4" @click="register">注册</v-btn>
          </div>
        </div>
      </v-card-text>
      <v-divider style="margin-top: 20px"></v-divider>
      <div
          style="display: flex;
           justify-content: center;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 14px;
            height: 30px;
"
      >
        注册即代表您已阅读并同意<a style="color: #66b1ff" href="" >用户协议</a>和<a style="color: #66b1ff" href="" >隐私政策</a>
    </div>
    </v-card>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { load } from 'recaptcha-v3'
import Load from "@/plugins/load";

export default {
  name: "RegPage",
  data: () => ({
    username: '',
    password: '',
    nick: '',
    email: '',
    code: '',
    time: 0,
  }),
  mounted() {
    document.title = '注册'
    if(this.$cookie.getCookie('token')){
      if(this.$route.query.callback){
        window.location.href = this.$route.query.callback
      }else{
        this.$router.push({path: '/home'})
      }
    }
  },
  methods:{
    register() {
      const l = new Load()
      l.show()
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('register').then((token) => {
          this.$axios.post(
                '/api/register/',
              JSON.stringify({
                'password': this.$data.password,
                'username': this.$data.username,
                'nick': this.$data.nick,
                'account': this.$data.email,
                'authcode': this.$data.code,
                'g-recaptcha-response': token,
              }
          )).then(
              res => {
                const json = res.data
                l.hide()
                  this.$cookie.setCookie('token', json['data']['access_token']);
                  Swal.fire({title: "登录成功", html: '欢迎回来！', icon: "success", confirmButtonText: '点击跳转'} ).then(() => {
                        this.$router.go(0)
                  })
                }
          ).catch(
              err => {
                const res = err.response
                l.hide()
                  Swal.fire( "登录失败" ,  res.data.message ,  "error" )
                }
          )
        })
        recaptcha.hideBadge()
      })
    },
  async get_mail_code(){
    this.setTime()
    load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
      recaptcha.execute('getAuthCode').then((token) => {
        this.$axios.post(
            '/api/security/verify/MFA/code/2/login/',
            JSON.stringify(
                {
                  account: this.$data.email,
                  'g-recaptcha-response': token
                }
            ),
            {
              headers: {
                Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
              },
            }
        ).then(res => {
          const data = res.data;
          if(data['success']){
            Swal.fire(
                '成功',
                '验证码已发送至您的邮箱，请注意查收',
                'success'
            )
          }else{
            Swal.fire(
                '错误',
                data['message'],
                'error'
            )
          }
        }).catch(err=>{
          const data = err.response.data;
          Swal.fire(
              '错误',
              data['message'],
              'error'
          )
        })
      })
    })
  },
  setTime() {
    //倒计时初始化秒数
    let t = 60
    const _this = this;
    const id = setInterval(function (){
      if (t === 0) {
        _this.$data.time = t;
        t = 60
        clearInterval(id);//停止调用函数
      } else {
        _this.$data.time = t;
        t--
      }
    }, 1000);
    //设置倒计时
  }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  padding: 10px 10px 0;
}
@media(max-width: 600px) {
  .n-cccard{
    width: 96%;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    width: 380px;
  }
}
.btns{
  display: flex;
  justify-content: space-between;
}
</style>