<template>
  <v-card class="n-cccard">
    <div style="padding: 10px">
      <v-card-title>具有帐号访问权限的第三方应用</v-card-title>
      <v-card-subtitle style="white-space: normal">您已授权这些网站和应用访问您的部分 BoxCat 帐号数据（可能包括敏感信息）。请移除您不再信任或使用的网站和应用的访问权限。</v-card-subtitle>
    </div>
    <br>
    <v-divider></v-divider>
    <div style="width: 100%; padding: 0">
      <v-expansion-panels
          variant="accordion"
          :multiple="true"
      >
        <v-expansion-panel elevation="0" style="" v-for="app in apps" :key="app">
          <v-expansion-panel-title>
            <div style="display: flex; align-items: center">
              <v-avatar size="30px" rounded>
                <v-img :src="app.icon" v-if="app.icon"></v-img>
                <v-img src="img/no_avatar.webp" alt="" v-else></v-img>
              </v-avatar>
              <div style="margin-left:10px; font-size: 16px" v-text="app.name">
              </div>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text style="background: #e4e4e4;">
            <div class="app-auth-list">
              <div class="app-auth">
                <div class="app-auth-info" style="margin-top: 0">
                  <div class="app-auth-info-title">
                    可访问以下内容
                  </div>
                  <div class="app-auth-info-desc">
                    <div style="display: flex; align-items: center">
                      <v-icon icon="mdi-account-circle-outline" color="#66b1ff" size="20"></v-icon>
                      <span style="font-size: 16px; margin-left: 5px">帐号基本信息</span>
                    </div>
                    <div style="margin-left: 25px;color: rgba(0,0,0,0.65);font-size: 14px">
                      <div v-for="a in app.access.read.base" :key="a">
                        <span v-text="a"></span>
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 5px">
                      <v-icon icon="mdi-lock-outline" color="#66b1ff" size="20"></v-icon>
                      <span style="font-size: 16px; margin-left: 5px">帐号隐私信息</span>
                    </div>
                    <div style="margin-left: 25px;color: rgba(0,0,0,0.65);font-size: 14px">
                      <div v-for="a in app.access.read.privacy" :key="a">
                        <span v-text="a"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="app-auth-info">
                  <div class="app-auth-info-title">
                    可修改以下内容
                  </div>
                  <div class="app-auth-info-desc">
                    <div style="display: flex; align-items: center">
                      <v-icon icon="mdi-account-circle-outline" color="#66b1ff" size="20"></v-icon>
                      <span style="font-size: 16px; margin-left: 5px">帐号基本信息</span>
                    </div>
                    <div style="margin-left: 25px;color: rgba(0,0,0,0.65);font-size: 14px">
                      <div v-for="a in app.access.write.base" :key="a">
                        <span v-text="a"></span>
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 5px">
                      <v-icon icon="mdi-lock-outline" color="#66b1ff" size="20"></v-icon>
                      <span style="font-size: 16px; margin-left: 5px">帐号隐私信息</span>
                    </div>
                    <div style="margin-left: 25px;color: rgba(0,0,0,0.65);font-size: 14px">
                      <div v-for="a in app.access.write.privacy" :key="a">
                        <span v-text="a"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="app-auth-info">
                  <div class="app-auth-info-title">
                    "<span v-text="app.name"></span>"的主页地址
                  </div>
                  <div class="app-auth-info-desc">
                      <span style="font-size: 16px;"><a style="color: deepskyblue" :href="app.home">访问该应用官网</a></span>
                  </div>
                </div>
                <div class="app-auth-info">
                  <div class="app-auth-info-title">
                    首次授予访问权限的日期：
                  </div>
                  <div class="app-auth-info-desc">
                    <span style="font-size: 16px;" v-text="app.authTime"></span>
                  </div>
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: 20px">
                  <v-btn color="primary" variant="tonal">举报此应用</v-btn>
                  <v-btn color="#66b1ff" variant="tonal" @click="remove(app.client_id)">移除访问权限</v-btn>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: 'AuthApps',
  props: {
    info: Object
  },
  components: {
  },
  data: () =>({
    apps: []
  }),
  mounted() {
    this.get_list()
  },
  methods: {
    get_list(){
      this.$axios.get(
          '/oauth2/user/privacy/apps/scope/list/',
          {
            headers: {
              Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
            }
          }
      ).then(res => {
        this.$data.apps = res.data.data.list
      }).catch(err => {
        Swal.fire({
          title: '获取应用列表失败',
          text: err.response.data['message'],
          icon: 'error',
          confirmButtonText: '确定'
        })
      })
    },
    remove(client_id){
      this.$axios.delete(
          '/oauth2/user/privacy/apps/scope/remove/' + client_id + '/',
          {
            headers: {
              Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
            }
          }
      ).then(res => {
        Swal.fire({
          title: '移除成功',
          text: res.data['data']['message'],
          icon: 'success',
          confirmButtonText: '确定'
        }).then(
            () => {
              this.get_list()
            }
        )
      }).catch(err => {
        Swal.fire({
          title: '获取应用列表失败',
          text: err.response.data['message'],
          icon: 'error',
          confirmButtonText: '确定'
        })
      })
    }
  }
}
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
}
.app-auth-list{
  background: white;
  width: 100%;
}
.app-auth{
  display: flex;
  padding: 5px 20px 20px;
  flex-direction: column
}
.app-auth-info-title{
  display: flex;
  color: rgba(0,0,0,0.65);
}
.app-auth-info-desc{
  display: flex;
  flex-direction: column;
}
.app-auth-info{
  display: flex;
  margin-top: 20px
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
  .app-auth-info{
    flex-direction: column;
  }
  .app-auth-info-desc{
    margin-top: 5px;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
  .app-auth-info-title{
    width: 45%;
  }
  .app-auth-info-desc{
    width: 55%
  }
}
</style>