<script setup>

</script>

<template>
  <div class="n-title">
    <p class="title">隐私与数据</p>
    <p class="desc">与您使用的有权访问您帐号的 BoxCat 应用或第三方应用相关内容和偏好设置</p>
  </div>
  <!-- 基本资料 -->
  <v-card class="n-cccard" elevation="1">
    <div style="padding: 10px 10px 0;">
      <v-card-title>应用和服务</v-card-title>
      <v-card-subtitle>用于管理具有您的数据访问权的应用.</v-card-subtitle>
      <v-card-text style="margin-top: 10px" >
        <router-link to="/settings/privacy-apps/" :apps="authApps">
          <div class="n-card" style="height: 50px">
            <div class="n-card-content">
              <div class="n-card-col">
                <div class="c-name" style="flex-basis: 285px;">具有帐号访问权限的应用</div>
                <div class="n-card-value" >您已授权这些应用访问部分数据</div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <div v-if="authApps.count > 0">
        <router-link to="/settings/privacy-apps/">
          <div class="n-card">
            <div class="n-card-content">
              <v-chip
                  color="primary"
                  size="large"
                  v-for="app in authApps.list"
                  :key="app"
                  style="margin-right: 10px"
              >
                <v-avatar
                    rounded="0"
                    size="25"
                    style="margin-right: 5px;"
                >
                  <v-img :src="app.icon" alt="" v-if="app.icon"></v-img>
                  <v-img src="img/no_avatar.webp" alt="" v-else></v-img>
                </v-avatar>
                <span v-text="app.name"></span>
              </v-chip>
            </div>
          </div>
        </router-link>
        </div>
      </v-card-text>
    </div>
  </v-card>
  <!-- 登录选项 -->
  <v-card class="n-cccard">
    <div style="padding: 10px;">
      <v-card-title>更多选项</v-card-title>
      <v-card-subtitle>用于管理您的数据的其他选项.</v-card-subtitle>
      <v-card-text style="margin-top: 10px">
        <router-link to="/seetings/privacy/account-close/">
          <div class="n-card">
            <div class="n-card-content">
              <div style="margin-right: 15px;"><v-icon icon="mdi-delete-off-outline" size="20"></v-icon></div>
              <div class="n-card-col">
                <div class="c-name" >注销账户</div>
                <div class="n-card-value">删除您保存于 BoxCat 中的全部数据</div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: 'PrivacyData',
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    log:[
      {
        action: '登录',
        ip: '127.*.*.1',
        time: '2021-06-02 12:00:00',
        area: {
          country: '中国',
          province: '上海',
          city: '上海'
        },
      }
    ],
    authApps: [
      {
        name: 'WeCatTalk',
        icon: 'https://s1.ax1x.com/2023/06/02/pCpEqBV.png',
      }
    ]
  }),
  mounted() {
    document.title = '数据与隐私'
    this.get_list()
  },
  methods: {
    get_list(){
      this.$axios.get(
          '/oauth2/user/privacy/apps/list/',
          {
            headers: {
              Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
            }
          }
      ).then(res => {
        this.$data.authApps = res.data.data
      }).catch(err => {
        Swal.fire({
          title: '获取应用列表失败',
          text: err.response.data['message'],
          icon: 'error',
          confirmButtonText: '确定'
        })
      })
    }
  }
};
</script>
<style scoped>
.n-title{
  text-align: center;
  margin: 20px;
}
.n-title .title{
  font-size: 30px;
}
.n-title .desc{
  font-size: 25px;
  color: #999;
}
.n-card{
  display:flex;
  min-height: 50px;
  align-items: center;
}
.c-name{
  font-size: 18px;
  display: flex;
  align-items: center;
}
.n-card-content{
  display:flex;
}
@media(max-width: 600px) {
  .n-cccard{
    margin: 5px;
  }
  .n-card{
    height: auto;
    justify-content: space-between;
  }
  .c-name{
    padding-top:2px;
  }
  .n-card-content{
    align-items: center;
  }
  .n-card-value{
    display: flex;
    font-size: 14px;
    flex: 1;
    color: gray;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
  .n-card{
    height: 60px;
  }
  .c-name{
    flex-basis: 250px;
  }
  .n-card-content{
    flex-grow: 1;
    text-align: center;
    align-items: center;
    font-size: 10px;
  }
  .n-card-col{
    display: flex;
    flex-basis: 500px;
  }
  .n-card-value{
    display: flex;
    font-size: 15px;
    min-width: 150px;
    flex: 1;
    color: gray;
  }
}
</style>