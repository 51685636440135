import axios from "axios";

let config = {
    baseURL: process.env.NODE_ENV === 'production'? "https://user.cat.beer": 'http://user.cat.beer',
    timeout: 60 * 1000, // Timeout
    headers: {
        "Content-Type": "application/json",
    }
};
const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
);

export default {
  install: function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Vue.config.globalProperties.$axios = _axios;
  },
};
