<template>
  <v-card class="n-cccard">
    <v-card-title>身份验证器</v-card-title>
    <v-card-subtitle style="white-space: normal">
      您将通过身份验证器应用获取验证码，而无需等待短信。即使您的手机处于离线状态，该应用也仍能正常运行。<br>
      首先，从 Google Play 商店或 iOS App Store 中下载 Google 身份验证器。
    </v-card-subtitle>
    <v-card-text style="margin-top: 10px">
      <v-btn variant="tonal" color="primary" v-if="!user.safety.mfa.method.google.enable || bind_mode === true" @click="bind_mode = true">
        + 设置身份验证器
      </v-btn>
      <v-btn variant="tonal" color="primary" v-if="user.safety.mfa.method.google.enable">
        - 移除身份验证器
      </v-btn>
    </v-card-text>
  </v-card>
  <div v-if="bind_mode">
    <div style="
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    background: rgba(0,0,0,0.5);
">
  </div>
  <div class="bind">
    <div style="padding: 20px;height: 100%">
      <div style="font-size: 20px;">设置身份验证器应用</div>
      <br>
      <div style="height: 100%">
        <!--绑定-->
        <div class="bind-box" v-if="!verify_mode">
          <div style="font-size: 15px; margin-left: 10px">在 Google 身份验证器应用中，点按 +<br>选择扫描二维码</div>
          <div style="display: flex;flex-direction: column;align-items: center;margin-top: -50px;">
            <div>
              <QrCode></QrCode>
            </div>
          </div>
          <div style="display: flex;flex-direction: row;justify-content: right">
            <div><v-btn variant="plain" color="primary" @click="bind_mode = false;">取消</v-btn></div>
            <div><v-btn variant="plain" color="primary" @click="verify_mode = true">下一步</v-btn></div>
          </div>
        </div>
        <div class="verify-box" v-else>
          <div>
            <div>请输入您在该应用中看到的 6 位数验证码</div>
            <div style="margin-top: 10px">
              <v-text-field color="primary" label="输入验证码" v-model="code" variant="outlined">
              </v-text-field>
            </div>
          </div>
          <div style="display: flex;flex-direction: row;justify-content: space-between">
            <div>
              <div><v-btn variant="plain" color="primary">上一步</v-btn></div>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: flex-end">
              <div><v-btn variant="plain" color="primary" @click="$router.push({name: 'security-2fa-google'})">取消</v-btn></div>
              <div><v-btn variant="plain" color="primary" :disabled="code.length !== 6" @click="verify">验证</v-btn></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import QrCode from "@/components/dashboard/Settings/security/mfa/BindBox/QrCode.vue";
import {load} from "recaptcha-v3";
import Load from "@/plugins/load";

export default {
  name: "MfaGoogle",
  components: {QrCode},
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    code: '',
    bind_mode: false,
    verify_mode: false,
  }),
  mounted() {
    document.title = '身份验证器'
  },
  methods: {
    verify(){
      const l = new Load()
      l.show()
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('getAuthCode').then((token) => {
      this.$axios.put(
          '/api/security/verify/MFA/bind/1/',
          {
            "code": this.$data.code,
            safety_token: this.$cookie.getCookie('SafetyToken'),
            'g-recaptcha-response': token,
            account: 1,
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
            }
          }
      ).then(res => {
        const data = res.data
        l.hide()
        Swal.fire({
          title: '成功',
          text: data.message,
          icon: 'success',
          confirmButtonText: '确定'
        }).then(() => {
          this.$data.verify_mode = false
          this.$data.bind_mode = false
          this.$router.push({
            name: 'security-2fa-google',
          })
        })
      }).catch(err => {
        const res = err.response
        l.hide()
        try{
          const data = res.data
          Swal.fire({
            title: '错误',
            text: data.message,
            icon: 'error',
            confirmButtonText: '确定'
          })
        }catch (e) {
          Swal.fire({
            title: '错误',
            text: '服务器错误',
            icon: 'error',
            confirmButtonText: '确定'
          }).then(() => {
            this.$router.go(0)
          })
        }
      })
        })
      })
    }
  }
}
</script>
<style scoped>
.n-cccard{
  box-shadow: none;
}
.bind{
  position: absolute;
  z-index: 999;
  background: white;
}
.bind-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.verify-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media(max-width: 600px) {
  .n-cccard{
    margin: 5px;
  }
  .bind{
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    transform: translate(0, 0);
  }
  .bind-box{
    height: 80%;
  }
  .verify-box{
    height: 80%;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
  .bind{
    width: 500px;
    height: 500px;
    top:50%;
    left: 50%;
    transform: translate(-250px, -250px);
    border: gray 1px solid;
    border-radius: 15px;
    box-shadow: #999999;
  }
  .bind-box{
    height: 400px;
  }
  .verify-box{
    height: 400px;
  }
}
</style>