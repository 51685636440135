<template>
  <v-footer
      class=" text-center d-flex flex-column"
      color="primary"

  >
    <div style="margin-bottom: 10px">
      <a :key="icon" v-for="icon in icons" :href="icon.link">
      <v-btn
          size="20"
          class="mx-4"
          :icon="icon.icon"
          variant="text"
          color="white"
      ></v-btn></a>
    </div>

    <div class="pt-0" style="color: white">
      <a href="https://beian.miit.gov.cn" target="_blank" class="el-link el-link--default is-underline">
        <span style="color: white">
          粤ICP备2020099968号
        </span>
      </a>
    </div>
    <v-divider></v-divider>
    <div style="color: white">
      Copyright &copy; <strong><a href="https://boxcat.org" style="color: white">BoxCat</a></strong> All rights reserved.
    </div>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    icons: [
      {
      icon: 'mdi-twitter',
      link: 'https://twitter.com/BoxCateam'
    },
      {
      icon: 'mdi-github',
      link: 'https://github.com/BoxCatTeam'
    }
    ],
  }),
}
</script>
<style scoped>

</style>