import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import MainBoard from "@/components/MainBoard.vue";
import ProfileCenter from "@/components/dashboard/ProfileCenter.vue";
import SecurityCenter from "@/components/dashboard/SecurityCenter.vue";
import PrivacyData from "@/components/dashboard/PrivacyData.vue";
import HomePage from "@/components/dashboard/HomePage.vue";
import SettingsChange from "@/components/home/SettingsChange.vue";
import SetNick from "@/components/dashboard/Settings/Profile/SetNick.vue";
import SetBirth from "@/components/dashboard/Settings/Profile/SetBirth.vue";
import SetSex from "@/components/dashboard/Settings/Profile/SetSex.vue";
import SetAvatar from "@/components/dashboard/Settings/Profile/SetAvatar.vue";
import PassWord from "@/components/dashboard/Settings/security/PassWord.vue";
import SecurityTel from "@/components/dashboard/Settings/security/SecurityTel.vue";
import SafetyVerification from "@/components/SafetyVerification.vue";
import VerifyPwd from "@/components/SafetyVerification/VerifyPwd.vue";
import Verify2FA from "@/components/SafetyVerification/Verify2FA.vue";
import GoogleAuthenticator from "@/components/SafetyVerification/methods/GoogleAuthenticator.vue";
import VerifyEmail from "@/components/SafetyVerification/methods/VerifyEmail.vue";
import VerifyTel from "@/components/SafetyVerification/methods/VerifyTel.vue";
import VerifyChoices from "@/components/SafetyVerification/methods/VerifyChoices.vue";
import AuthApps from "@/components/dashboard/Settings/privacy/AuthApps.vue";
import SecurityEmail from "@/components/dashboard/Settings/security/SecurityEmail.vue";
import ErrorPage from "@/components/ErrorPage.vue";
import LoginPage from "@/components/user/LoginPage.vue";
import MfA from "@/components/dashboard/Settings/security/MfA.vue";
import MfaGoogle from "@/components/dashboard/Settings/security/mfa/MfaGoogle.vue";
import SecurityMfa from "@/components/dashboard/Settings/security/SecurityMfa.vue";
import RegPage from "@/components/user/RegPage.vue";
import MfaTel from "@/components/dashboard/Settings/security/mfa/MfaTel.vue";
import MfaEmail from "@/components/dashboard/Settings/security/mfa/MfaEmail.vue";
import ForgetPwd from "@/components/user/ForgetPwd.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainBoard,
    props: true,
    redirect: '/login',
    children:[
      {
        path: 'home',
        name: 'homepage',
        component: HomePage,
        props: true,
      },
      {
        path: 'profile',
        name: 'profile',
        component: ProfileCenter,
        props: true,
      },{
        path: 'security',
        name: 'security',
        component: SecurityCenter,
        props: true,
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: PrivacyData,
        props: true,
      }
    ]
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    props: true,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsChange,
    redirect: '/profile',
    props: true,
    children:[
      {
        path: 'profile-nick',
        name: 'profile-nick',
        component: SetNick,
        props: true,
      },
      {
        path: 'profile-birth',
        name: 'profile-birth',
        component: SetBirth,
        props: true,
      },
      {
        path: 'profile-sex',
        name: 'profile-sex',
        component: SetSex,
        props: true,
      },
      {
        path: 'profile-avatar',
        name: 'profile-avatar',
        component: SetAvatar,
        props: true,
      },
      {
        path: 'security-password',
        name: 'security-password',
        component: PassWord,
        props: true,
      },
      {
        path: 'security-tel',
        name: 'security-tel',
        component: SecurityTel,
        props: true,
      },
      {
        path: 'security-email',
        name: 'security-email',
        component: SecurityEmail,
        props: true,
      },
      {
        path: 'security-2fa',
        name: 'security-2fa',
        component: SecurityMfa,
        props: true,
        children: [
          {
            path: '',
            name: 'security-2fa-list',
            component: MfA,
            props: true
          },
          {
            path: 'google',
            name: 'security-2fa-google',
            component: MfaGoogle,
            props: true,
          },
          {
            path: 'tel',
            name: 'security-2fa-tel',
            component: MfaTel,
            props: true,
          },
          {
            path: 'email',
            name: 'security-2fa-email',
            component: MfaEmail,
            props: true,
          },
        ]
      },
      {
        path: 'privacy-apps',
        name: 'privacy-apps',
        component: AuthApps,
      }
    ]
  },
  {
    path: '/SafetyVerification',
    name: 'security-verify',
    component: SafetyVerification,
    props: true,
    children:[
      {
        path: 'password',
        name: 'verify-password',
        component: VerifyPwd,
        props: true,
      },
      {
        path: '2fa',
        name: 'verify-2fa',
        component: Verify2FA,
        props: true,
        redirect: '/SafetyVerification/2fa/choice',
        children:[
          {
            path: 'choice',
            name: 'verify-2fa-choice',
            component: VerifyChoices,
            props: true
          },
          {
            path: 'google',
            name: 'verify-2fa-google',
            component: GoogleAuthenticator,
            props: true
          },
          {
            path: 'email',
            name: 'verify-2fa-email',
            component: VerifyEmail,
            props: true
          },{
            path: 'tel',
            name: 'verify-2fa-tel',
            component: VerifyTel,
            props: true
          }
        ]
      }
    ]
  },
  {
    path:'/login',
    name: 'login',
    component: LoginPage,
    props: true,
  },
  {
    path:'/register',
    name: 'register',
    component: RegPage,
    props: true,
  },
  {
    path:'/forget',
    name: 'forget',
    component: ForgetPwd,
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error'
  }
]

const router = createRouter({
  history: process.env.NODE_ENV === 'production'? createWebHistory() : createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'BoxCat 账号';
  if(to.path !== '/login' && !document.cookie && to.path !== '/register' && to.path !== '/forget'){
    next('/login');
  }else{
    next();
  }
});
export default router
