import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'
import VueCropper from 'vue-cropper';
import './plugins/axios'
import 'vue-cropper/dist/index.css'
import { VueCookieNext } from 'vue-cookie-next'
import VueGtag from "vue-gtag";

const app = createApp(App)
    .use(router)
    .use(vuetify)
    .use(VueCropper)
    .use(VueGtag, {
        config: { id: "G-GTPPE2760B" }
    }, router)
    .use(VueCookieNext)
    .use(axios)
    .mount('#app')

VueCookieNext.config({
    expire: '7d',
    path: '/',
    domain: '.cat.beer',
    secure: '',
    sameSite: '',
})

if(!app.$cookie.getCookie('token')){
    app.$router.push('/login')
}
console.warn('[Warning] 这是一个 Warning OvO')
console.error('[Error] 这是一个 Error QAQ')
