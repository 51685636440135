
<template>
  <p style="text-align: center">请输入您 身份验证器 中的6位验证码</p>
  <br>
  <v-text-field color="primary" label="验证码" variant="outlined" :maxlength="6" v-model="code">
  </v-text-field>
  <div class="btns">
    <router-link :to="{name: 'verify-2fa-choice', query:{callback: this.$route.query.callback}}">
      <v-btn variant="plain" color="primary">
        其他方式
      </v-btn>
    </router-link>
    <v-btn variant="tonal" color="primary" @click="nextPage()" :disabled="code.length !== 6">
      下一步
    </v-btn>
  </div>
</template>

<style scoped>
.btns{
  display: flex;
  justify-content: space-between;
}
</style>

<script>
import Swal from "sweetalert2";
import Load from "@/plugins/load";

export default {
  props: {
    user: {
      type: Object,
    },
  },
  data: () => ({
    code: '',
    callback: ''
  }),
  mounted() {
    this.$data.callback = this.$route.query.callback ? this.$route.query.callback : '/home'
  },
  methods: {
    nextPage() {
      const load = new Load()
      load.show()
      this.$axios.post(
          '/api/security/verify/MFA/auth/',
          JSON.stringify({
            'code': this.$data.code,
            'google': true,
            'get_safety_token': this.$cookie.getCookie('getAuthCode'),
          }),
          {
            headers: {
              Authorization: 'Bearer ' + this.$cookie.getCookie('token')
            }
          }
      ).then(res => {
        const json = res.data
        load.hide()
        this.$cookie.setCookie('SafetyToken', json['data']['safety_token'], {expire: '10min'});
        this.$cookie.removeCookie('getAuthCode')
        this.$router.push({
          name: this.$data.callback,
        });
      }).catch(err =>{
        const res = err.response
        const data = res.data
        load.hide()
        Swal.fire(
            '错误',
            data['message'],
            'error'
        ).then(() => {
          this.$router.go(0)
        })
      })
    },
  },
}
</script>
