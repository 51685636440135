<script setup>

</script>

<template>
  <!-- 基本资料 -->

  <v-card class="n-cccard">
    <div style="display: flex; align-items: center; justify-content: center; margin: 10px auto 5px;">
      <div>
        <img src="img/logo_pink.svg" style="  height: 40px;" alt="">
      </div>
      <div style="margin-bottom: 5px; padding-left: 10px;font-size:30px;color: rgb(245,143,178)">BoxCat</div>
    </div>
    <v-card-title style="text-align: center;font-size: 25px" v-text="user.info.nick"></v-card-title>
    <div style="text-align: center">
    <v-chip color="primary">
      <v-avatar start>
        <v-img :src="user.info.avatar"></v-img>
      </v-avatar>
      <span v-text="user.info.email"></span>
    </v-chip>
    </div>
    <br>
    <v-card-subtitle style="white-space: normal; text-align: center">
      如需继续操作，请先验证您的身份
    </v-card-subtitle>
    <v-card-text style="margin-top: 10px">
      <v-form>
        <v-text-field color="primary" label="输入您的密码" type="password" v-model="password" variant="outlined">
        </v-text-field>
        <div class="btns">
          <v-btn variant="plain" color="primary" @click="$router.go(-1)">
            忘记密码
          </v-btn>
          <v-btn variant="tonal" color="primary" @click="nextPage()" :disabled="password.length < 8">
            下一步
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>

import {load} from "recaptcha-v3";
import Swal from "sweetalert2";
import Load from "@/plugins/load";

export default {
  name: 'VerifyPwd',
  props: {
    user: {
      type: Object,
    },
    next: String
  },
  data: () => ({
    password: '',
  }),
  mounted() {
    if(this.$cookie.getCookie('SafetyToken')) this.$router.push({path: this.$data.callback})
  },
  methods: {
    nextPage() {
      const l = new Load()
      l.show()
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('verify_pwd').then((token) => {
          this.$axios.post(
              '/api/security/verify/password/',
              JSON.stringify(
                  {
                    password: this.$data.password,
                    'g-recaptcha-response': token
                  }
              ),
              {
                headers: {
                  Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
                },
              }
          ).then(res => {
            const data = res.data;
            l.hide()
            if(this.user.safety.mfa.enable){
              this.$cookie.setCookie('getAuthCode', data['data']['code'], {expire: '10min'});
              const method = this.user.safety.mfa.method.google.enable? 'verify-2fa-google': this.user.safety.mfa.method.tel.enable? 'verify-2fa-tel': 'verify-2fa-email';
              this.$router.push({
                name: method,
                query: {
                  callback: this.$route.query.callback
                },
              });
            }else{
              l.hide()
              this.$cookie.setCookie('SafetyToken', data['data']['code'], {expire: '10min'});
              const url = this.$route.query.callback? this.$route.query.callback: 'home'
              this.$router.push({
                name: url
              });
            }
          }).catch(err=>{
            const data = err.response.data;
            l.hide()
            Swal.fire(
                '错误',
                data['message'],
                'error'
            )
          })
        })
        recaptcha.hideBadge()
      });
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
  width: 100%;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;
    min-height: 450px;
  }
}
.btns{
  display: flex;
  justify-content: space-between;
}
</style>