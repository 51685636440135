<template>
  <p style="text-align: center">使用您的辅助手机 <span v-text="user.safety.mfa.method.tel.number"></span> 获取验证码</p>
  <br>
  <div style="flex: 1">
    <v-text-field color="primary" label="请输入完整的手机号" :maxlength="12" variant="outlined" v-model="tel">
    </v-text-field>
  </div>
  <div style="display: flex; justify-content: space-between">
    <div style="flex: 1">
      <v-text-field color="primary" label="验证码" :maxlength="6" variant="outlined" v-model="code">
      </v-text-field>
    </div>
    <div >
      <v-btn variant="tonal" style="height:50px; margin-left: 20px" color="primary" @click="get_sms_code" :disabled="time > 0">
        获取验证码<span v-if="time > 0">(<span v-text="time"></span>)</span>
      </v-btn>
    </div>
  </div>
  <div class="btns">
    <router-link :to="{name: 'verify-2fa-choice', query:{callback: this.$route.query.callback}}">
      <v-btn variant="plain" color="primary">
        其他方式
      </v-btn>
    </router-link>
    <v-btn variant="tonal" color="primary" @click="nextPage()" :disabled="code.length !== 6">
      下一步
    </v-btn>
  </div>
</template>
<script>
import Load from "@/plugins/load";
import Swal from "sweetalert2";
import {load} from "recaptcha-v3";

export default {
  props: {
    user: {
      type: Object,
    },
    next: String,
  },
  data: () => ({
    tel: '',
    code: '',
    time: 0,
    callback: ''
  }),
  methods: {
    async get_sms_code(){
      this.setTime()
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('getAuthCode').then((token) => {
          this.$axios.post(
              '/api/security/verify/MFA/code/4/verify/',
              JSON.stringify(
                  {
                    account: this.$data.tel,
                    'g-recaptcha-response': token
                  }
              ),
              {
                headers: {
                  Authorization: 'Bearer ' + this.$cookie.getCookie('token'),
                },
              }
          ).then(res => {
            const data = res.data;
            if(data['success']){
              Swal.fire(
                  '成功',
                  '验证码已发送至您的手机，请注意查收',
                  'success'
              )
            }else{
              Swal.fire(
                  '错误',
                  data['message'],
                  'error'
              )
            }
          }).catch(err=>{
            const data = err.response.data;
            Swal.fire(
                '错误',
                data['message'],
                'error'
            )
          })
        })
      })
    },
    nextPage() {
      const load = new Load()
      load.show()
      this.$axios.post(
          '/api/security/verify/MFA/auth/',
          JSON.stringify({
            'code': this.$data.code,
            'google': false,
            'get_safety_token': this.$cookie.getCookie('getAuthCode'),
          }),
          {
            headers: {
              Authorization: 'Bearer ' + this.$cookie.getCookie('token')
            }
          }
      ).then(res => {
        const json = res.data
        load.hide()
        this.$cookie.setCookie('SafetyToken', json['data']['safety_token'], {expire: '10min'});
        this.$cookie.removeCookie('getAuthCode')
        this.$router.push({
          name: this.$data.callback,
        });
      }).catch(err =>{
        const res = err.response
        const data = res.data
        load.hide()
        Swal.fire(
            '错误',
            data['message'],
            'error'
        ).then(() => {
          this.$router.go(0)
        })
      })
    },
    setTime() {
      //倒计时初始化秒数
      let t = 60
      const _this = this;
      const id = setInterval(function (){
        if (t === 0) {
          _this.$data.time = t;
          t = 60
          clearInterval(id);//停止调用函数
        } else {
          _this.$data.time = t;
          t--
        }
      }, 1000);
      //设置倒计时
    }
  },
  mounted() {
    this.$data.callback = this.$route.query.callback ? this.$route.query.callback : 'home'
  }
}
</script>
<style scoped>
.btns{
  display: flex;
  justify-content: space-between;
}
</style>