<template>
<router-view :user="user"></router-view>
</template>
<script>
export default {
  name: "SecurityMfa",
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
  })
};
</script>
<style scoped>

</style>