<script setup>

</script>

<template>
  <div class="n-title">
    <p class="title">个人资料</p>
    <p class="desc">您的个人资料和您在 BoxCat 偏好设置</p>
  </div>
  <!-- 基本资料 -->
  <v-card class="n-cccard" elevation="1">
    <div style="padding: 10px;">
    <v-card-title>基本资料</v-card-title>
    <v-card-subtitle>其他 BoxCat 服务使用者可能会看到部分资料。</v-card-subtitle>
    <v-card-text style="margin-top: 10px">
      <router-link to="/settings/profile-avatar">
          <div style="height: 80px" class="n-card">
            <div class="n-card-content">
              <div class="c-name">头像</div>
              <div class="n-card-value">个性头像可帮助其他用户认出您。</div>
            </div>
            <v-avatar
                style="border: 1px solid gray;z-index: 0"
                size="60"
            >
              <img :src="user.info.avatar" :alt="user.info.nick" style="width: 60px">
            </v-avatar>
          </div>
      </router-link>
      <v-divider></v-divider>
      <router-link to="/settings/profile-nick/">
      <div class="n-card">
        <div class="n-card-content">
          <div class="c-name">昵称</div>
          <div class="n-card-value" v-text="user.info.nick"></div>
        </div>
          <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
      </div>
      </router-link>
      <v-divider></v-divider>
      <router-link to="/settings/profile-sex/">
        <div class="n-card">
          <div class="n-card-content">
            <div class="c-name">性别</div>
            <div class="n-card-value" v-text="user.info.sex"></div>
          </div>
          <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
        </div>
      </router-link>
      <v-divider></v-divider>
      <router-link to="/settings/profile-birth/">
        <div class="n-card">
          <div class='n-card-content'>
            <div class="c-name">生日</div>
            <div class="n-card-value" v-text="user.info.birthday"></div>
          </div>
          <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
        </div>
      </router-link>
      <v-divider></v-divider>
    </v-card-text>
    </div>
  </v-card>
  <!-- 联系方式 -->
  <v-card class="n-cccard">
    <div style="padding: 10px;">
      <v-card-title>联系信息</v-card-title>
      <v-card-text style="margin-top: 10px">
        <router-link to="/settings/security-email/">
          <div class="n-card">
            <div class="n-card-content">
              <div class="c-name">电子邮件</div>
              <div class="n-card-value" v-text="user.info.email"></div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
        <router-link to="/settings/security-tel/">
          <div class="n-card">
            <div class="n-card-content">
              <div class="c-name">手机号码</div>
              <div class="n-card-value" v-text="user.info.tel"></div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'ProfileCenter',
  props:{
    user: Object
  },
  data: () => ({
  }),
  mounted() {
    document.title = '个人资料'
  }
};
</script>
<style scoped>
.n-title{
  text-align: center;
  margin: 20px;
}
.n-title .title{
  font-size: 30px;
}
.n-title .desc{
  font-size: 25px;
  color: #999;
}
.n-card{
  display:flex;
  height: 60px;
  align-items: center;
}
.c-name{
  display: flex;
  color: gray;
}
.n-card-content{
  display:flex;
}
@media(max-width: 600px) {
  .n-cccard{
    margin: 5px;
  }
  .n-card{
    justify-content: space-between;
  }
  .c-name{
    padding-top:2px;
    flex-direction: column;
  }
  .n-card-content{
    flex-direction: column;
  }
  .n-card-value{
    font-size: 16px;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
  .c-name{
    flex-basis: 150px;
  }
  .n-card-content{
    flex-grow: 1
  }
  .n-card-value{
    font-size: 18px;
  }
}
</style>