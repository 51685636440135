<script setup>

</script>

<template>
  <div class="n-title">
    <p class="title">安全中心</p>
    <p class="desc">有助于确保您帐号安全的设置和建议</p>
  </div>
  <!-- 基本资料 -->
  <v-card class="n-cccard" elevation="1">
    <div style="padding: 10px;">
      <v-card-title>近期安全性活动</v-card-title>
      <v-card-text style="margin-top: 10px" >
        <router-link to="/">
          <div v-if="log.length === 0" style="font-size: 25px">数据加载中...</div>
          <div v-for="l in log" :key="l">
          <div class="n-card">
            <div class="n-card-content">
              <div class="c-name" style="min-width: 50px" v-text="l.action"></div>
              <div class="n-card-values">
                <div class="n-card-value" v-text="l.time" ></div>
                <div class="n-card-value"><span style="min-width: 35px">IP</span>：<span v-text="l.ip"></span></div>
                <div class="n-card-value" style="min-width: 250px"><span style="min-width: 35px">地点</span>：<span v-text="l.location.province"></span>&nbsp;-&nbsp;<span v-text="l.location.city"></span>&nbsp;-&nbsp;<span v-text="l.location.region"></span></div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
          <v-divider></v-divider>
          </div>
        </router-link>
      </v-card-text>
    </div>
  </v-card>
  <!-- 登录选项 -->
  <v-card class="n-cccard">
    <div style="padding: 10px;">
      <v-card-title>您的 BoxCat 帐号登录选项</v-card-title>
      <v-card-subtitle>请务必及时更新这些信息<br>确保您始终都能访问自己的 BoxCat 帐号</v-card-subtitle>
      <v-card-text style="margin-top: 10px">
        <router-link to="/settings/security-2fa/">
          <div class="n-card">
            <div class="n-card-content">
              <div style="margin-right: 15px;"><v-icon icon="mdi-shield-lock-outline" size="20"></v-icon></div>
              <div class="n-card-col">
                <div class="c-name" >两步验证</div>
                <div class="n-card-value">
                  <span v-if="user.safety.mfa.enable">已</span>
                  <span v-if="!user.safety.mfa.enable">未</span>
                  启用
                </div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
        <router-link to="/settings/security-password/">
          <div class="n-card">
            <div class="n-card-content">
              <div style="margin-right: 15px;"><v-icon icon="mdi-lock-check-outline" size="20"></v-icon></div>
              <div class="n-card-col">
                <div class="c-name" >密码</div>
                <div class="n-card-value">上次更改时间：<span>2022-02-02</span></div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
        <router-link to='/settings/security-email/'>
          <div class="n-card">
            <div class="n-card-content">
              <div style="margin-right: 15px;"><v-icon icon="mdi-email-outline" size="20"></v-icon></div>
              <div class="n-card-col">
                <div class="c-name">电子邮件</div>
                <div class="n-card-value" v-text="user.info.email"></div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
        <router-link to="/settings/security-tel/">
          <div class="n-card">
            <div class="n-card-content">
              <div style="margin-right: 15px;"><v-icon icon="mdi-cellphone-information" size="20"></v-icon></div>
              <div class="n-card-col">
                <div class="c-name">手机号码</div>
                <div class="n-card-value" v-text="user.info.tel"></div>
              </div>
            </div>
            <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
          </div>
        </router-link>
        <v-divider></v-divider>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'SecurityCenter',
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    log:[

    ]
  }),
  mounted() {
    document.title = '账号与安全'
    this.$axios.get(
        '/api/security/log/',
        {
          headers: {
            'Authorization': 'Bearer ' + this.$cookie.getCookie('token')
          }
        }
    ).then(res => {
      this.$data.log = res.data.data
    }).catch(err => {
      console.log(err)
    })
  }
};
</script>
<style scoped>
.n-title{
  text-align: center;
  margin: 20px;
}
.n-title .title{
  font-size: 30px;
}
.n-title .desc{
  font-size: 25px;
  color: #999;
}
.n-card{
  display:flex;
  min-height: 50px;
  align-items: center;
}
.c-name{
  font-size: 18px;
  display: flex;
  align-items: center;
}
.n-card-content{
  display:flex;
}
@media(max-width: 600px) {
  .n-cccard{
    margin: 5px;
  }
  .n-card{
    height: auto;
    justify-content: space-between;
  }
  .c-name{
    padding-top:2px;
    margin-right: 15px;
  }
  .n-card-content{
    align-items: center;
  }
  .n-card-value{
    display: flex;
    font-size: 14px;
    flex: 1;
    color: gray;
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
  }
  .n-card{
    height: 60px;
  }
  .c-name{
    flex-basis: 150px;
  }
  .n-card-content{
    flex-grow: 1;
    text-align: center;
    align-items: center;
    font-size: 10px;
  }
  .n-card-col{
    display: flex;
    flex-basis: 500px;
  }
  .n-card-value{
    display: flex;
    font-size: 15px;
    min-width: 200px;
    flex: 1;
    color: gray;
  }
  .n-card-values{
    display: flex;
    justify-content: space-between;
    align-items: center
  }
}

@media(max-width: 820px) {
  .n-card-value{
    min-width: 180px;
  }
}

@media(max-width: 760px) {
  .n-card-value{
    min-width: 170px;
  }
}

@media(max-width: 700px) {
  .n-card-value{
    min-width: 160px;
  }
}

@media(max-width: 640px) {
  .n-card-value{
    min-width: 150px;
  }
}
</style>