<template>
  <div v-if="user.safety.mfa.method.google.enable">
  <router-link :to="{name: 'verify-2fa-google', query:{callback: this.$route.query.callback, authCode: this.$route.query.authCode}}">
    <div class="n-card">
      <div class="n-card-content">
        <div style="margin-right: 15px;"><v-icon icon="mdi-shield-lock-outline" size="20"></v-icon></div>
        <div class="n-card-col">
          <div class="c-name" >Google Authenticator</div>
          <div class="n-card-value">使用您身份验证器中显示的验证码</div>
        </div>
      </div>
      <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
    </div>
  </router-link>
  <div v-if="user.safety.mfa.method.tel.enable">
  <v-divider></v-divider>
  </div>
  <router-link :to="{name: 'verify-2fa-tel', query:{callback: this.$route.query.callback, authCode: this.$route.query.authCode}}">
    <div class="n-card">
      <div class="n-card-content">
        <div style="margin-right: 15px;"><v-icon icon="mdi-cellphone-information" size="20"></v-icon></div>
        <div class="n-card-col">
          <div class="c-name" >辅助手机</div>
          <div class="n-card-value">向您的辅助手机发送一条验证码</div>
        </div>
      </div>
      <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
    </div>
  </router-link>
  </div>
  <div v-if="user.safety.mfa.method.email.enable">
  <v-divider></v-divider>
  <router-link :to="{name: 'verify-2fa-email', query:{callback: this.$route.query.callback, authCode: this.$route.query.authCode}}">
    <div class="n-card">
      <div class="n-card-content">
        <div style="margin-right: 15px;"><v-icon icon="mdi-email-outline" size="20"></v-icon></div>
        <div class="n-card-col">
          <div class="c-name" >辅助邮箱</div>
          <div class="n-card-value">向您的辅助邮箱发送一条验证码</div>
        </div>
      </div>
      <v-icon style="width: 50px" icon="mdi-chevron-right"></v-icon>
    </div>
  </router-link>
  </div>
</template>
<script>
export default {
  name: 'VerifyChoices',
  props: {
    user: Object,
  },
  data: () => ({
  }),
  mounted() {
  },
};
</script>
<style scoped>
.n-card{
  display:flex;
  min-height: 50px;
  align-items: center;
}
.c-name{
  font-size: 18px;
  display: flex;
  align-items: center;
}
.n-card-content{
  display:flex;
}
@media(max-width: 600px) {
  .n-card{
    height: auto;
    justify-content: space-between;
  }
  .c-name{
    padding-top:2px;
  }
  .n-card-content{
    align-items: center;
  }
  .n-card-value{
    display: flex;
    font-size: 14px;
    flex: 1;
    color: gray;
  }
}
@media(min-width: 600px) {
  .n-card{
    height: 60px;
  }
  .n-card-content{
    flex-grow: 1;
    text-align: center;
    align-items: center;
    font-size: 10px;
  }
  .n-card-col{
    display: flex;
    flex-direction: column;
  }
  .n-card-value{
    display: flex;
    font-size: 15px;
    min-width: 150px;
    color: gray;
  }
}
</style>