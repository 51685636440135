<script setup>

</script>

<template>
  <!-- 基本资料 -->
  <v-card class="n-cccard">
    <v-card-title></v-card-title>
    <v-card-subtitle style="white-space: normal">定期修改密码以确保您的 BoxCat 帐号安全。</v-card-subtitle>
    <v-card-text style="margin-top: 10px">
      <v-form>
        <v-text-field color="primary" label="新密码" type="password" v-model="password" variant="outlined">
        </v-text-field>
        <div class="btns">
          <v-btn variant="plain" color="primary" @click="$router.go(-1)">
            取消
          </v-btn>
          <v-btn variant="tonal" color="primary" :disabled="password.length < 8" @click="change_pwd">
            提交
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import Swal from "sweetalert2";
import {load} from "recaptcha-v3";

export default {
  name: 'PassWord',
  props: {
    user: {
      type: Object,
    }
  },
  data: () => ({
    password: '',
  }),
  mounted() {

  },
  methods: {
    change_pwd() {
      load('6LcMtUUmAAAAAMrgkm3RPuyS6Ck88OX0ztHtv1Zk',{useRecaptchaNet: true}).then((recaptcha) => {
        recaptcha.execute('write_pwd').then((token) => {
      this.$axios.put(
          '/oauth2/user/security/write/password/',
          JSON.stringify({
            value: this.$data.password,
            safety_token: this.$cookie.getCookie('SafetyToken'),
            'g-recaptcha-response': token,
          }),
          {
            headers: {
              'Authorization': 'Bearer ' + this.$cookie.getCookie('token')
            }
          }
      ).then(res =>{
        const data = res.data;
        if(data['success']){
          Swal.fire({
            title: '成功',
            text: '修改成功',
            icon: 'success',
            confirmButtonText: '确定',
          }).then(() => {
            this.$router.push({name: 'security'});
          })
        }
      }).catch(
          err=>{
            const data = err.response.data;
            Swal.fire({
              title: '错误',
              text: data['message'],
              icon: 'error',
              confirmButtonText: '确定',
            })
          }
      )
        })})
    }
  }
};
</script>
<style scoped>
.n-cccard{
  display: block !important;
  max-width: 600px;
  margin: auto;
}
@media(max-width: 600px) {
  .n-cccard{
    box-shadow: none; /* 取消阴影 */
    border: none; /* 取消边框 */
  }
}
@media(min-width: 600px) {
  .n-cccard{
    margin: 20px;
    padding: 10px;

  }
}
.btns{
  display: flex;
  justify-content: flex-end;
}
</style>